<template>
  <div>
    <div>
      <!-- <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/yeguang.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">成为会员</div>
        </div>
      </div> -->
      <div class="inputFrom"
        v-if="verifyshow">
        <div>头像</div>
        <div class="radio"
          style="border:none;">
          <van-uploader v-model="ImageList"
            multiple
            capture="camera"
            class="imgs"
            :max-count="1"
            :after-read="afterRead"
            :before-delete="jkDelete"
            :deletable="true" />

        </div>
        <div>社区</div>
        <div>
          <input v-model="idList.OCodeName"
            type="text"
            @click="idListOrg = true"
            readonly="readonly"
            placeholder="请选择社区" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ocodelist"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </div>
        <div>网格</div>
        <div>
          <input v-model="idList.GName"
            type="text"
            @click="idListGId = true"
            readonly="readonly"
            placeholder="请选择您所在网格" />
          <van-popup v-model="idListGId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="gidlist"
              value-key="Title"
              @cancel="idListGId = false"
              @confirm="onidListGId">
            </van-picker>
          </van-popup>
        </div>
        <div>姓名</div>
        <div>
          <input v-model="idList.Name"
            placeholder="请输入您的姓名" />
        </div>
        <div>身份证号</div>
        <div>
          <input v-model="idList.IdCard"
            placeholder="请输入身份证号" />
        </div>
        <div>手机号</div>
        <div>
          <input v-model="idList.Mobile"
            placeholder="请输入手机号" />
        </div>
        <div>地址</div>
        <div>
          <textarea rows="3"
            v-model="idList.Address"
            placeholder="请输入地址" />
          </div>
        <div>座右铭</div>
        <div>
          <input v-model="idList.Motto"
            placeholder="请输入座右铭" />
        </div>
        <div>专业</div>
        <div>
          <input v-model="idList.Major"
            placeholder="请输入专业" />
        </div>
        <div>兴趣特长</div>
        <div>
          <input v-model="idList.Interest"
            placeholder="请输入兴趣特长" />
        </div>
        <div>是否党员</div>
        <div>
          <van-radio-group v-model="idList.PartyMember"
              direction="horizontal" class="radio">
              <van-radio name="0">否</van-radio>
              <van-radio name="1">是</van-radio>
          </van-radio-group>
        </div>
        <div>志愿者</div>
        <div>
         <van-radio-group v-model="idList.Volunteer"
              direction="horizontal" class="radio">
              <van-radio name="0">否</van-radio>
              <van-radio name="1">是</van-radio>
            </van-radio-group>
        </div>
      </div>
      <div class="buttons"
        v-if="verifyshow">
        <!-- <van-button style="border: 1px solid #e6e6e6"
          type="default"
          @click="fanhui">返回</van-button> -->
        <van-button type="info" style="width:80%;"
          @click="huiyuan">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WeGetGridList,
  WeMyMemberInfo,
  GetOrgansNullToken,
  WxSaveMember
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      gidlist: [],
      NativeCantonCodedata: [],
      ocodelist: [],
      ImageList: [{ url: '' }],
      idList: {
        OrgCode: "",
        Name: "", //姓名、
        IdCard: "", //身份证号、
        GName: "", //网格名称
        GId: "", //网格ID、
        Address: "", //通讯地址、
        Mobile: "", //手机号码、
        PartyMember: "", //否党员
        Volunteer: "", //志愿者
        Pwd: "", //密码、
      },
      idListGId: false,
      addshow: false,
      verifyshow: true,
      LiveCity: false,
      Political: false,
      OCodeshow: false,
      idListOrg: false,
      mumberXq: "",

    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.MemberInfo();
    this.gitocodelist();
  },
  methods: {
    // 获取会员详情
    // getOpenId()
    MemberInfo () {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == '0') {
          // 头像
          if (res.data.data.HeadImg != '') {
            this.ImageList[0].url = res.data.data.HeadImg
            this.idList.HeadImg = res.data.data.HeadImg
          } else {
            this.ImageList = [{ url: '' }]
          }
          // 姓名
          this.idList.Name = res.data.data.Name
          // 座右铭
          this.idList.Motto = res.data.data.Motto
          // 身份证号
          this.idList.IdCard = res.data.data.IdCard
          // 手机
          this.idList.Mobile = res.data.data.Mobile
          // 通讯地址
          this.idList.Address = res.data.data.Address
          // 专业
          this.idList.Major = res.data.data.Major
          // 兴趣特长
          this.idList.Interest = res.data.data.Interest
          // 支付密码
          this.idList.PayPwd = res.data.data.PayPwd
          // 社区编码
          this.idList.OrgCode = res.data.data.OrgCode
          // 社区名称
          this.idList.OCodeName = res.data.data.OrgName
          // 网格名称
          this.idList.GName = res.data.data.GName
          // 网格ID
          this.idList.GId = res.data.data.GId
          // 党员
          this.idList.PartyMember = res.data.data.PartyMember + "";
          // 志愿者
          this.idList.Volunteer = res.data.data.Volunteer + "";
          if (this.idList.OrgCode != '') {
            this.getGridList(this.idList.OrgCode);
          }
        }
      })
    },
    //上传照片
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.idList.HeadImg = res.data.picurl
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除照片
    jkDelete (file) {
      this.ImageList = []
      this.idList.HeadImg = ""
    },
    // 提交
    huiyuan: function () {
      this.idList.OpenID = getOpenId();
      console.log(getOpenId());
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      if (this.idList.OrgCode == "") {
        Toast.fail("请选择社区");
        return false;
      }
      if (this.idList.GName == "") {
        Toast.fail("请选择网格");
        return false;
      }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      if (reg.test(this.idList.IdCard) == false) {
        Toast.fail("请输入正确的身份证号");
        return false;
      }
      if (this.idList.Mobile == "") {
        Toast.fail("请输入手机号");
        return false;
      }
      if (this.idList.Address == "") {
        Toast.fail("请输入通讯地址");
        return false;
      }
      this.idList.OpenID = getOpenId();
      WxSaveMember(this.idList).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {

            // if (window.__wxjs_environment === "miniprogram") {
            //   window.wx.miniProgram.reLaunch({
            //     url: "/pages/index/member/index",
            //   });
            // } else {
            //   alert("请在小程序中打开");
            // }
            this.$router.go(-1)
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    fanhui: function () {
      window.wx.miniProgram.reLaunch({
        url: "/pages/index/index",
      });
    },
    gitocodelist () {
      GetOrgansNullToken({
        level: 4,
        kind: 1,
      }).then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    ridpush: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 选择社区
    onidListOrg (val) {
      this.gidlist = []
      this.idList.GName = ''
      this.idList.GId = ''
      this.idList.OrgCode = val.organcode;
      this.idList.OCodeName = val.organname;
      this.idListOrg = false;
      this.getGridList(val.organcode);
    },

    // 小区(组)选择器
    onidListGId (val) {
      this.idList.GId = val.GId;
      this.idList.GName = val.Title;
      this.idListGId = false;
    },

    // 社区
    onocode (val) {
      console.log(val);
      this.idList.OCodeName = val.selectedOptions[0].OrganName;
      this.idList.OCode = val.value;
      // this.ridpush(val.value);
      this.getGridList(val.value);
      this.OCodeshow = false;
    },
    // 网格
    getGridList: function (row) {
      WeGetGridList({ organCode: row }).then((res) => {
        this.gidlist = res.data.data;
      });
    },
    // 地址
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value);
      this.idList.NativeCantonCode = RegCity[0];
      this.idList.NativeCantonName = RegCity[1];
      this.LiveCity = false;
    },
    onPolitical (val) {
      this.idList.PoliticalCode = val.Coding;
      this.idList.PoliticalName = val.Name;
      this.Political = false;
    },
    addressfrom (value) {
      // 地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
    // 按钮
    dengji () {
      if (this.idList.RIdTitle == undefined) {
        Toast.fail("请选择所住小区");
        return false;
      }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      this.addshow = true;
      this.verifyshow = false;
    },
    pageshow () {
      this.addshow = false;
      this.verifyshow = true;
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.inputFrom textarea {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.inputFrom .radio {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>